<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
          :src="appLogoImage"
          :height="appLogoImage.includes('arasana') ? '30' : '60'"
          alt="logo"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('Page not found') }} 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 {{ $t('The address you want to go to is not available') }}.
        </p>

        <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{path:'/'}"
        >
          {{ $t('Go to Homepage') }}
        </b-button>

        <!-- image -->
        <b-img
            fluid
            :src="imgUrl"
            :alt="$t('Error page')"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      appLogoImage: require('@/assets/images/logo/logo.png'),
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
